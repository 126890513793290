import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/brand/logo-dark.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/brand/logo-light.png");
;
import(/* webpackMode: "eager", webpackExports: ["Counter"] */ "/vercel/path0/src/components/counter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/vercel/path0/src/components/ui/scroll-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent","SheetClose"] */ "/vercel/path0/src/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/layout/header/desktop/v1/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/layout/header/desktop/v2/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/src/sections/hero/v1/index.tsx");
